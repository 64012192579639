import React, {useState, useEffect, Fragment} from 'react';
import { connect } from 'react-redux';
import { Dropdown, Modal } from 'react-bootstrap';
import { Link,useLocation, useHistory } from 'react-router-dom';

import ConfirmDialoag from '../modals/ConfirmDiloag';
import {getprogramdatesinCart, getgendersdisplay} from '../../helper';
import { LogoWhite, CardLoader, ProgramLoader } from '../reuse/SVG';
import { addToCart, addToCartNonLoginUser, camperListSelectCamperScreen } from '../../services/programService';


const CartItem = props => {
    let location = useLocation();
    let history = useHistory();

    const [cartitem, setItem]                                  = useState(props.Item)    
    const[confirmshow, setConfirmshow]                         = useState(false);
    const[numbderofCampers, setnumberofCampers]                = useState(false);
    const[addtionalExptotalPrice, setAddtionalTotalPrice]      = useState(0);
    const[cartTotalPrice, setCartTotalPrice]                   = useState();
    const[loading, setLoadningAction]                          = useState(false);
    const[redirectToAddCamper, setRedirectToCamper]            = useState(false);
    const[singleCamperWithNoQAndA,setsingleCamperWithNoQAndA]  = useState(false); 
    const[singleCamperWithQAndA,setsingleCamperWithQAndA]      = useState(false); 
    const[singleCamperGroupCode, setsingleCamperGroupCode]     = useState(false); 
    const[filterCamperListData, setFilterCamperListData]       = useState();
    const[associatedCartInfo, setAssociatedCartInfo]           = useState([]);


    useEffect(() => {
        setItem(props.Item);
        setnumberofCampers(props.Item.numberOfBooking);
        setAssociatedCartInfo(props.Item.associatedCartInfo || [])

        let cartitemdetails = cartitem;
        let addtionalexptotalprice = 0;
        let questions = cartitemdetails.questions;
        let _totalDueAmount = 0;

        camperListSelectCamperScreen().then(res => {

            let usecamperList = res.data.responseData.result;
            let filtercamperlist = [];

            usecamperList.map((camper) => {
                setRedirectToCamper(false);
                filtercamperlist.push(camper);
            });

            if(filtercamperlist.length === 1 && questions.length > 0){
                setsingleCamperWithQAndA(true);   
            }

            if(cartitemdetails.campers !== undefined){
                cartitemdetails.campers.map(item => {
                    if(item.additionalExperience !== undefined){
                        item.additionalExperience.map(addtional => {
                            addtionalexptotalprice = addtionalexptotalprice + addtional.price *addtional.numberOfBooking;
                        })
                    }
                })
            }

            if(filtercamperlist.length === 1 && questions.length === 0 && addtionalexptotalprice === 0 && (cartitemdetails.commuterPrice === 0 || cartitemdetails.commuterPrice === 'undefined' || cartitemdetails.commuterPrice === null)){
                setsingleCamperWithNoQAndA(true);
            }

            setFilterCamperListData(filtercamperlist);
            
        })




        if(cartitemdetails.campers !== undefined){
            cartitemdetails.campers.map(item => {
                if(item.additionalExperience !== undefined){
                    item.additionalExperience.map(addtional => {
                        addtionalexptotalprice = addtionalexptotalprice + addtional.price * addtional.numberOfBooking;
                    })
                }
            })
            setAddtionalTotalPrice(addtionalexptotalprice);
        }


        setCartTotalPrice(cartitemdetails.registrationFee * (cartitemdetails.campers.length || 1));

    },[props])




    const handelQuantity = (programId, actiontype) =>{

        let addtocartapi  = props.loggedIn ? addToCart : addToCartNonLoginUser;
    
        if(actiontype === 'decrement'){
            
            if(numbderofCampers !== 1){
                setLoadningAction(true);
                let params = {programId, numberOfBooking:'-1'} 
                let numberofcamper = numbderofCampers-1;
                addtocartapi(params).then(res => {
                    setLoadningAction(false);
                })

                setnumberofCampers(numberofcamper);
                
            }
        }
        if(actiontype === 'increment'){
            setLoadningAction(true);
            let numberofcamper = numbderofCampers+1;
            let params = {programId, numberOfBooking:'1'}
            addtocartapi(params).then(res => {
                setLoadningAction(false);
                setnumberofCampers(numberofcamper);
            })
            
        }
    }

    const handelNoGroupCode = () => {
        const oldproductpre = localStorage.getItem('cartitemsloginuser') ? localStorage.getItem('cartitemsloginuser') : "[]";
        const arrayproduct =  JSON.parse(oldproductpre);
        let cartdata ={"cart_id":cartitem._id, "camperlist": filterCamperListData, "selectedcamperList": filterCamperListData[0]._id, "additionalexp":[]}
        arrayproduct[0] = cartdata; 
        localStorage.setItem('cartitemsloginuser', JSON.stringify(arrayproduct));    
        history.push({
            pathname: '/confirmdetails',
            state:{"numbderofCampers": 1,
            "cart_id": cartitem._id,
            "selectedcamperList": filterCamperListData[0]._id
        }});
    }



    const getProgramPriceByDates = (allPrices, selectedDateByProgram) => {
        const _res = allPrices.filter(el => selectedDateByProgram.find((id) => el._id === id));
        let _totalPrice = 0;
        if(_res.length){
            _res.map(i => _totalPrice += i.standardPrice)
        }
        return _totalPrice > 0 ? `$${_totalPrice.toFixed(2)}` : 'Free';
    }


    const getProductPriceFun = _list => {
        let _all_product_price = 0;
        if(_list.length){
            for (let i = 0; i < _list.length; i++) {
                _all_product_price = _all_product_price +  calculateTotalPriceForItem(_list[i].items);
            }
        }

        return _all_product_price;
    }

    const calculateTotalPriceForItem  = _item => {
        let totallPrice = Number(_item.price) * Number(_item.count)
         if(_item?.subItems?.length > 0){
            totallPrice = _item.subItems.reduce( (tp, si) => (tp + (Number(si.price) * Number(si.count)) ), totallPrice);
         }
         return totallPrice

    }

    const getProgramPriceByDates2 = (allPrices, selectedDateByProgram, numbderofCampers, _product_price) => {
        const _res = allPrices.filter(el => selectedDateByProgram.find((id) => el._id === id));
        let _totalPrice = 0;
        if(_res.length){
            _res.map(i => _totalPrice += i.standardPrice)
        }

        return _totalPrice ? ((_totalPrice * numbderofCampers) + getProductPriceFun(_product_price)).toFixed(2) : 'Free';
    }

    const getSelectedDates = (_all, _sel) => {
        const _res = _all.filter(el => _sel.find((id) => el._id === id));
        return _res.map(i => (
        <span className='cm_selected_date' key={i._id}>
            {getprogramdatesinCart(i.startDate,i.endDate)}
        </span>
        ));
    }



    const getTotalAmount = data => {
        if(data.byDates === 1){

            const _res = data.additionalDates.filter(el => data.selectedDates.find((id) => el._id === id));
            let _totalPrice = 0;

            for (let i = 0; i < data.campers.length; i++) {
                if(data.campers[i].isCommuter){
                    for (let j = 0; j < _res.length; j++) {
                        _totalPrice = _totalPrice + _res[j].commuterPrice;
                    }
                }else{
                    for (let j = 0; j < _res.length; j++) {
                        _totalPrice = _totalPrice + _res[j].standardPrice;
                    }
                }
            }

            return _totalPrice;

        }else{
            let _total_amount = 0;

            for (let i = 0; i < data.campers.length; i++) {
                if(data.campers[i].isCommuter){
                    _total_amount = _total_amount + data.commuterPrice
                }else{
                    _total_amount = _total_amount + data.campRegistrationFee
                }
            }

            return _total_amount ? _total_amount : 0 

        }
    }





    const getTotalAmount2 = data => {
        if(data.byDates === 1){
            const _res = data.additionalDates.filter(el => data.selectedDates.find((id) => el._id === id));
            let _totalPrice = 0;

            for (let j = 0; j < _res.length; j++) {
                _totalPrice = _totalPrice + _res[j].standardPrice;
            }

            return _totalPrice ? _totalPrice * numbderofCampers : 0;

        }else{
            return data.campRegistrationFee;
        }
    }

return(
    <React.Fragment>
        <Modal
            dialogClassName="h_auto"
            show={singleCamperGroupCode}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Would you like to add group code?</h3>
                    <div className="d-flex justify-content-center">
                    <button className="btn btn-light mr-4 pl-5 pr-5" onClick={()=>{handelNoGroupCode()}}>No</button>
                    <Link className="btn btn-primary pl-5 pr-5"   to={
                        {     
                            pathname: '/selectcampers',
                            state:{
                                "numbderofCampers":numbderofCampers, 
                                "cart_id": cartitem._id, 
                                "singleCamper": true, 
                                "programId":cartitem.programId, 
                            }
                        }
                    } >Yes </Link>
</div>

                </div>
            </Modal.Body>
          </Modal>

        

     <div className="cart_card" key={cartitem._id}>
        {props.miniLoader[cartitem._id] ? <div className='cart_loader'><div className="program_loader"><ProgramLoader /></div> </div> : ''}
     {cartitem.isSpecialDeal ? <span className="SD_tag">Special Offers</span> : ''}
     {loading ? <CardLoader/>: ''}
                 {
                    cartitem.campImage ? <Link to={`/camps/${cartitem.programId}`}><img src={cartitem.campImage}/></Link> : <div className="no_img"><Link to="#"><LogoWhite /></Link></div>
                 }  
                
                <h3><Link to={`/camps/${cartitem.programId}`}>{cartitem.campName} - {cartitem.programName}</Link></h3>
                {cartitem.byDates === 1 ? (
                    <p>{getSelectedDates(cartitem.additionalDates, cartitem.selectedDates)}</p> 
                ) : (
                    <p>{cartitem.programId === "621d3620c9cc80756fe0a171" ? "TBA" : getprogramdatesinCart(cartitem.startDate,cartitem.endDate)}<span className="d_md_block">&nbsp; &nbsp; &nbsp;</span> 
                    {cartitem.addressLine1 !=='' ? <> Address : {cartitem.address}</> : ''}
                 </p> 
                )}
                
                 

                
                <p>Age : {cartitem.ageGroup} <span className="d_md_block">&nbsp; &nbsp; &nbsp;</span> Gender : {getgendersdisplay(cartitem.gender)}</p>
                <p>Number of Bookings : {numbderofCampers}</p>

                {
                    addtionalExptotalPrice > 0 ? 
                    <React.Fragment> 
                    <p className='m-0'>Additional Experiences Price: </p>
                    </React.Fragment> 
                    :''
                }

                {
                    associatedCartInfo.length ? <ul className='mt-0'>
                        {associatedCartInfo.map(({items}, i) => (
                            <li key={items?.itemId}>{i+1 } : <strong>{items?.title}</strong> - {items?.count} {items?.color} {items?.sizes}</li>
                        ))}
                    </ul> : ''
                }


                 {
                     cartitem.detailConfirmed === 0 &&

                <div className="quantity mb-3">
                    <button className="inc" onClick={()=>{handelQuantity(cartitem.programId, 'decrement')}}/>
                    <b>{numbderofCampers}</b>
                    <button className="dec" onClick={()=>{handelQuantity(cartitem.programId,'increment')}}/>
                </div>
}
                
                
                <div className="d-flex align-items-start flex-column cart_actions">
                    <div className="ml-auto text-right">
                       
                {
                    cartitem.detailConfirmed === 1 ? <span className="cm_color_green">Details Confirmed</span> :
                     
                    (cartitem.isApplicable === 1 && (!props.lastName || props.lastName === 'undefined') && (!props.phone || props.phone === 'undefined') && !props.gender && !props.dob && !props.address ) ? <Link
                    className="btn btn-primary rounded-0 mb-2"
                    to={{     
                        pathname: '/profile/information',
                        state:{"numbderofCampers":numbderofCampers, "cart_id": cartitem._id}
                    }} > Confirm Details </Link> : redirectToAddCamper ? 
                    <Link
                        className="btn btn-primary rounded-0 mb-2"
                        to={{     
                            pathname: '/profile/information',
                            state:{"numbderofCampers":numbderofCampers, "cart_id": cartitem._id}
                        }} > Confirm Details </Link>
                    :
                    singleCamperWithQAndA ? 
                    <Link
                        className="btn btn-primary rounded-0 mb-2"
                        to={{     
                            pathname: '/selectcampers',
                            state:{"numbderofCampers":numbderofCampers, 
                                "cart_id": cartitem._id, 
                                "singleCamper": true,
                                "programId":cartitem.programId,
                            }
                        }}> Confirm Details </Link>
                    :
                    singleCamperWithNoQAndA ?
                    <button
                        onClick={() => setsingleCamperGroupCode(true)}
                        className="btn btn-primary rounded-0 mb-2">Confirm Details</button>
                    :
                    props.loggedIn ?
                    <Link
                        className="btn btn-primary rounded-0"
                        to={{     
                            pathname: '/selectcampers',
                            state:{"numbderofCampers":numbderofCampers, 
                                    "cart_id": cartitem._id, 
                                    "programId": cartitem.programId,
                                }
                        }}>Confirm Details</Link>
                    :
                    <Link
                        className="btn btn-primary rounded-0 mb-2"
                        to={{     
                            pathname: '/confirmdetails',
                            state:{"numbderofCampers":numbderofCampers, "cart_id": cartitem._id}
                        }}> Confirm Details </Link>
                }

                    

                    <small onClick={() => setConfirmshow(true)} className="d-block text-center cart_remove_camp">Remove program</small>
                    </div>


                    <div className="cart_price text-right mt-3">

                        {cartitem.detailConfirmed === 1 ? <h4>{getTotalAmount(cartitem) ? `$${getTotalAmount(cartitem)?.toFixed(2)}` : 'Free'}</h4> : <h4>{getTotalAmount2(cartitem) ? `$${getTotalAmount2(cartitem)?.toFixed(2)}` : 'Free'}</h4>}

                        

                        {/* {cartitem.byDates === 1 ? (
                            <h4>{getProgramPriceByDates(cartitem.additionalDates, cartitem.selectedDates)}</h4>
                        ) : (
                            cartitem.detailConfirmed === 1 ? <h4>{cartTotalPrice ? cartitem.additionalDiscount < (cartitem.campRegistrationFee * cartitem.campers.length)? `$${cartTotalPrice.toFixed(2)}` : 'Free' : 'Free' }</h4> : <h4>{cartTotalPrice ? `$${cartTotalPrice.toFixed(2)}` : 'Free' }</h4>
                        )}  */}
      
                    
                    {cartitem.byDates !== 1 ? <Dropdown className="price_detail_popover">
                        <Dropdown.Toggle><small className="cart_remove_camp">Price detail</small></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className="d-flex justify-content-between">
                                <span>Camp Registration Fee :</span>
                                <b className="color_primary">${getTotalAmount(cartitem)?.toFixed(2)}</b>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>Sales Tax :</span>
                                <b className="color_primary">${cartitem.taxPrice.toFixed(2)}</b>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>Convenience Fee :</span>
                                <b className="color_primary">${cartitem?.convenienceFee?.toFixed(2)}</b>
                            </div>
                            {
                                cartitem.discountPrice ? 
                                <div className="d-flex justify-content-between">
                                <span>Discount :</span>
                                <b className="color_primary">-${cartitem.discountPrice.toFixed(2)}</b>
                            </div> : ''
                            }
                            <div className="d-flex justify-content-between gt_total">
                                <span>Grand Total : </span>
                                <b>${(getTotalAmount(cartitem) + cartitem.taxPrice + cartitem?.convenienceFee).toFixed(2)}</b>
                            </div>

                        </Dropdown.Menu>
                        </Dropdown> : ''}

                    



                    {addtionalExptotalPrice >0 ? <div className="mt-3">${addtionalExptotalPrice.toFixed(2)} </div> : ''}

                    {
                        associatedCartInfo.length ? <ul>
                            {associatedCartInfo.map(({ items}) => (
                                <li key={items.itemId}>${calculateTotalPriceForItem(items).toFixed(2)}</li>
                            ))}
                        </ul> : ''
                    }


                        {cartitem.detailConfirmed === 1 
                        ? getTotalAmount(cartitem) ? 
                        cartitem.additionalDiscount < cartitem.campRegistrationFee 
                        ? `Total : $${(getTotalAmount(cartitem) + addtionalExptotalPrice + getProductPriceFun(associatedCartInfo)).toFixed(2)}` 
                        : '' : '' : cartTotalPrice 
                        ? `Total : $${(getTotalAmount2(cartitem) + addtionalExptotalPrice  + getProductPriceFun(associatedCartInfo)).toFixed(2)}` : '' }       
       

                    {/* {cartitem.byDates !== 1 ? <Fragment>
                        {cartitem.detailConfirmed === 1 ? getTotalAmount(cartitem) ? cartitem.additionalDiscount < cartitem.campRegistrationFee ? `Total : ${(getTotalAmount(cartitem) + addtionalExptotalPrice + getProductPriceFun(associatedCartInfo)).toFixed(2)}` : '' : '' : cartTotalPrice ? `Total : ${(getTotalAmount(cartitem) + addtionalExptotalPrice  + getProductPriceFun(associatedCartInfo)).toFixed(2)}` : '' }       
                    </Fragment> : <Fragment>
                        {`Total : ${(getProgramPriceByDates2(cartitem.additionalDates, cartitem.selectedDates, numbderofCampers, associatedCartInfo))}`}       
                        </Fragment>} */}

                        

                      
                    
                    </div>
                </div>
            <ConfirmDialoag
                show={confirmshow}
                hideModal={() => setConfirmshow(false)}
                ProgramName={cartitem.programName}
                action={(e) => {props.RemoveFromCart(cartitem._id); setConfirmshow(false); }}
                Message={["Are You Sure you want to remove ", <strong>{cartitem.programName}</strong>, " from cart ?"]} />      
            {
                cartitem?.campers.length ? <div>{cartitem.campers.map(item => <span className="cm_tag">{item.camperName}</span>)}</div> : ''
            }

            
            
            
            </div>
        </React.Fragment>        
)
}

const mapStateToProps = state => {
    let { loggedIn, userProfile } = state.user;
    let { camperList } = state.program;
    let { name, lastName, phone, gender, dob, address } = userProfile;

    return {
        loggedIn,
        camperList,
        name,
        lastName,
        phone,
        gender,
        dob,
        address
    };
}

export default connect(mapStateToProps, null)(CartItem);
